import * as React from "react";
import { Link } from "gatsby";
import Layout from "components/Layout";
import Seo from "components/Seo";

const SuccessPage = () => {
  return (
    <Layout>
      <Seo
        title="Congratulations on Registering for CLA Bootcamp 2024"
        description="A community for tech community builders, leaders, managers, developer advocates, and relations within and across Africa."
      />
      <section className="py-20">
        <div className="container max-w-screen-md w-full mx-auto px-6 text-center">
          <div className="flex justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className=" fill-green-500 w-32 h-32 md:h-40 md:w-40"
              viewBox="0 0 256 256"
            >
              <path d="M173.66,98.34a8,8,0,0,1,0,11.32l-56,56a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L112,148.69l50.34-50.35A8,8,0,0,1,173.66,98.34ZM232,128A104,104,0,1,1,128,24,104.11,104.11,0,0,1,232,128Zm-16,0a88,88,0,1,0-88,88A88.1,88.1,0,0,0,216,128Z"></path>
            </svg>
          </div>
          <h1 className="md:text-4xl text-2xl font-bold mb-2 leading-loose">
            Congratulations!
          </h1>
          <h2 className="text-xl mb-2">
            You have successfully enrolled for the bootcamp
          </h2>
          <div className="p-8 md:p-10 bg-gray-100 m-3 rounded-xl">
            <h3 className="text-xl font-bold">What's Next?</h3>
            <p className="my-3 text-gray-700">
              You will receive an email from our Educational community Manager
              on the next steps of the bootcamp process. In the meantime, use
              the link below to get the bootcamp's handbook to learn what to
              expect from the bootcamp{" "}
            </p>
            <div className="my-8">
              <Link
                to="/handbook"
                className=" border-primary border-2 font-medium text-primary py-4 px-6 rounded-lg block"
              >
                Read our Handbook
              </Link>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default SuccessPage;
